export class Properties {
    PropertyID: any;
    CountyID: any;
    EntityID: any;
    PropertyID_HomeAway: any;
    UnitID_HomeAway: any;
    Listing_AirBnb: any;
    StreetAddress: any;
    EntityName: any;
    CountyName: any;
    City: any;
    State: any;
    isActive: boolean;
    ZipCode: any;
    CreatedDate: any;
    UpdatedDate: any;
    TimeOffset: any;
    QBAddress:any;
    constructor() {
        this.CountyID = '';
        this.EntityID = '';
        this.isActive = true;
        this.TimeOffset = '';
        this.State = '';
    }
}

export class County {
    CountyID: any;
    CountyName: any;
    isDeleted: any;
}

export class Entity {
    EntityID: any;
    EntityName: any;
    isDeleted: any;
}

export class Vendor {
    VendorID: any;
    VendorName: any;
    PayeeBank: any;
    CardDetails: any;
    isActive: any;
}
