import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ReservationService {

  constructor(private _api: ApiService) { }
  getAllVendors(): Observable<any> {
    return this._api.apiCaller('get', '/PIMS/GetAllVendors');
  }
  getAllReservationStatus(): Observable<any> {
    return this._api.apiCaller('get', '/PIMS/GetAllReservationStatus');
  }
  addReservation(data: any) {
    return this._api.apiCaller('post', '/PIMS/AddReservations', data);
  }
  getReservationByID(ReservationID): Observable<any> {
    return this._api.apiCaller('get', '/PIMS/GetReservationByID/' + ReservationID);
  }
  updateReservation(data: any): Observable<any> {
    return this._api.apiCaller('post', '/PIMS/UpdateReservations', data);
  }
  getAllReservations(data): Observable<any> {
    return this._api.apiCaller('post', '/PIMS/GetAllReservations', data);
  }
  uploadModule(data): Observable<any> {
    return this._api.apiCaller('post', '/PIMS/UploadModule', data);
  }
  onGenerateMonthlyReport(data): Observable<any> {
    return this._api.apiCaller('post', '/PIMS/GenerateMonthlyPropertyReport', data);
  }
  onGenerateYearlyReport(data): Observable<any> {
    return this._api.apiCaller('post', '/PIMS/GenerateMonthlyReport', data);
  }
  onGenerateAllMonthlyReport(data): Observable<any> {
    return this._api.apiCaller('post', '/PIMS/GenerateMonthlyAllPropertyReport', data);
  }
  onGenerateAllYearlyReport(data): Observable<any> {
    return this._api.apiCaller('post', '/PIMS/GenerateYearlyReport', data);
  }
  onGenerateReport(data): Observable<any> {
    return this._api.apiCaller('post', '/PIMS/GetExtraReport', data);
  }
  blockReservationByID(ReservationID): Observable<any> {
    return this._api.apiCaller('get', '/PIMS/BlockReservation/' + ReservationID);
  }
  onGenerateVendorReport(data): Observable<any> {
    return this._api.apiCaller('post', '/PIMS/GetVendorReport', data);
  }
  onGenerateQBAccountingReport(data): Observable<any> {
    return this._api.apiCaller('post', '/PIMS/GetQBAccountingReport', data);
  }
  onGenerateAccountingReport(data): Observable<any> {
    return this._api.apiCaller('post', '/PIMS/GetAccountingReport', data);
  }
  onGenerateMaidReport(data): Observable<any> {
    return this._api.apiCaller('post', '/PIMS/GetMaidReport', data);
  }
}
