export class Reservations {
    ReservationID: any;
    Property: any;
    GuestName: any;
    GuestEmail: any;
    GuestPhone: any;
    ReservationIDValue: any;
    CheckinDate: any;
    CheckoutDate: any;
    TotalNights: any;
    PayoutDate: any;
    Status: any;
    ExpediaVirtualCard: any;
    Expires: any;
    CVV: any;
    BillingAddress: any;
    CardActivationDate: any;
    GrossBookingAmount: any;
    AdditionalGuestFee: any;
    AdministrativeFees: any;
    ServiceFees: any;
    Taxes: any;
    CleaningFees: any;
    Refund: any;
    Adjustments: any;
    TotalPayment: any;
    Collected: any;
    Vendor: any;
    Deductions: any;
    DataInAmericanIstBank: any;
    VendorPayement: any;
    CreatedDate: any;
    UpdatedDate: any;
    PropertyName: any;
    StatusName: any;
    VendorName: any;
    EntityName: any;
    CountyName: any;
    TimeOffset: any;
    PerStayTax: any;
    Discount: any;
    Resolution: any;
    Notes: any;
    ExtraNights: any; 
    ExtraNightsAmount: any;   
    constructor() {
        this.ReservationIDValue = '';
        this.GuestEmail = '';
        this.CheckinDate = '';
        this.CheckoutDate = '';
        this.GuestName = '';
        this.GuestPhone = '';
        this.Property = '';
        this.TotalNights = 0;
        this.PayoutDate = '';
        this.Status = 1;
        this.Vendor = '';
        this.GrossBookingAmount = '0';
        this.AdditionalGuestFee = '0';
        this.AdministrativeFees = '0';
        this.ServiceFees = '0';
        this.Taxes = '0';
        this.CleaningFees = '0';
        this.Refund = '0';
        this.Adjustments = '0';
        this.TotalPayment = '0';
        this.Collected = '0';
        this.Deductions = '0';
        this.VendorPayement = '0';
        this.CVV = null;
        this.CardActivationDate = null;
        this.Expires = null;
        this.ExpediaVirtualCard = null;
        this.BillingAddress = null;
        this.PerStayTax = '0';
        this.Discount = '0'; 
        this.Resolution = '0';  
        this.Notes = ''; 
        this.ExtraNights = '0';   
        this.ExtraNightsAmount = '0';  
    }
}
export class ReservationStatus {
    ReservationStatusID: any;
    ReservationStatusName: any;
}
export class Vendors {
    VendorID: any;
    VendorName: any;
    isActive: boolean;
}
export class ReservationListReq {
    PropertyID: any;
    EntityID: any;
    constructor() {
        this.PropertyID = '';
        this.EntityID = '';
    }
}
